import './App.scss';
import {Navigation} from './components/Navigation/navigation';
import Footer from "./components/Footer/footer";
import {Routes, Route, useLocation} from 'react-router-dom';
import Home from './Pages/Home/home';
import ProjectsPage from './Pages/Projects/projectsPage';
import SingleProjectPage from './Pages/SingleProject/singleProjectPage';
import MusicPage from './Pages/Music/musicPage';
import ContactPage from './Pages/Contact/contactPage';
import ResumePage from './Pages/Resume/resumePage';
import BlogPage from './Pages/Blog/blogPage';
import Nopage from './Pages/Nopage/nopage';
import { AnimatePresence, motion } from 'framer-motion';
import ScrollToTop from './components/ScrollToTop/scrollToTop';

function AnimatedRoutes() {
  const location = useLocation(); //This is used to know when the url changes. It's detected by the Routes 'key' / location.pathname updating on page change. Exit animation.

  return (
    <>
      <AnimatePresence mode="wait">
        <ScrollToTop/>
          <Routes location={location} key={location.pathname}> 
            
            <Route index element={<PageWrapper> <Home/> </PageWrapper>} />
            <Route path="/home" element={<PageWrapper> <Home /> </PageWrapper>} />
            <Route path="/projects" element={<PageWrapper> <ProjectsPage /> </PageWrapper>} />
            <Route path="/projects/page" element={<PageWrapper> <SingleProjectPage /> </PageWrapper>} />
            <Route path="/music" element={<PageWrapper> <MusicPage /> </PageWrapper>} />
            <Route path="/contact" element={<PageWrapper> <ContactPage /> </PageWrapper>} />
            <Route path="/resume" element={<PageWrapper> <ResumePage /> </PageWrapper>} />
            <Route path="/blog" element={<PageWrapper> <BlogPage /> </PageWrapper>} />
            <Route path="*" element={<PageWrapper> <Nopage /> </PageWrapper>} />
          </Routes>
      </AnimatePresence>
    </>
  );
}

function PageWrapper({children}){
  return (
    <motion.div 
      initial={{opacity: 0, x: 100}}
      animate={{opacity: 1, x: 0}}
      exit={{opacity: 0, x: -100}}
      transition={{duration: 0.5}}
    >
      {children}
    </motion.div>
  )
}

function App(){
  return (
    <>
      <Navigation/>
      <div class='site-container'>
        <AnimatedRoutes/>
      </div>
      <Footer/>
    </>
      
  )
}

export default App;

