import './banner.scss';
import {Link} from 'react-router-dom';
import bigLogo from '../../assets/images/Logo1.png';

function Banner(){

    const title = "Makkiah";
    const animatedText = ["Web Developer ","Guitarist ","Producer ","SMB Owner "];
    const desc = "From crafting websites for businesses to strumming guitar riffs, I thrive on creativity in all its forms."
    return (
        <div className='banner-wrapper'>
            <div className='banner-left'>
                <h1 className='banner-title'>{title}</h1>
                <p className='banner-subText'>{animatedText[0]} &bull; {animatedText[1]} &bull; {animatedText[2]} &bull; {animatedText[3]}</p>
                <p className='banner-desc'>{desc}</p>
                <div className='banner-div'>
                    <Link to='/music' className="banner-btn btn1">Music</Link>
                    <Link to='/resume' className="banner-btn btn2">Résumé</Link>
                </div>
            </div>
            <div className='banner-right'>
                <img src={bigLogo} alt="Makkiah Logo" className='banner-logo'/>
            </div>
        </div>
    )
}

export default Banner;