import './navigation.scss';
import {Link, useMatch, useResolvedPath} from 'react-router-dom';
import bigLogo from '../../assets/images/Logo1.png';


function CustomLink({to, children, ...props}){
    const resolvedPath = useResolvedPath(to)
    const isActive = useMatch({path: resolvedPath.pathname, end: true})

    return (
        <li className="nav-links-li">
            <Link to={to} className={isActive ? "nav-links-li-link-active" : 'nav-links-li-link'} {...props}>
                {children}
            </Link>
        </li>
        )
}

function Navigation() {

    const navLinks = [
        {
            name: "Home",
            to: "/home",
            subLinks: []
        },
        // {
        //     name: "Bio",
        //     to: "/bio",
        //     subLinks: [
        //         {
        //             name: "Goals",
        //             to: "/goals"
        //         }
        //     ]
        // },
        {
            name: "Résumé",
            to: "/resume",
            subLinks: []
        },
        // {
        //     name: "Projects",
        //     to: "/projects",
        //     subLinks: [
        //         {
        //             name: "Work Resume",
        //             to: "/resume"
        //         }
        //     ]
        // },
        {
            name: "Music",
            to: "/music",
            subLinks: []
        },
        // {
        //     name: "Blog",
        //     to: "/blog",
        //     subLinks: []
        // },
        // {
        //     name: "Contact",
        //     to: "/contact",
        //     subLinks: []
        // },
      ]

    return(
        <div className='nav-wrapper'>
            <div className='nav-container'>
            <Link
                to="/"
                className='nav-logo-link'
            >
                <img src={bigLogo} alt="Makkiah Logo" className='nav-logo'/>
            </Link>
            
            
            
            {/* Mobile Nav Bar */}
            <input type='checkbox' id='mobile-sidebar-active'/>
            <label for='mobile-sidebar-active' className='mobile-nav-open'>
                <i className="fa-solid fa-bars mobile-nav-icons"></i>
            </label>

            <label id='mobile-overlay' for='mobile-sidebar-active'></label>
            <div className='mobile-nav-container'>
                <label for='mobile-sidebar-active' className='mobile-nav-close'>
                    <i className="fa-solid fa-xmark mobile-nav-icons"></i>
                </label>

                <ul className="nav-links-ul">
                    {
                        navLinks.map((link, key) => {

                            return (
                                <CustomLink to={link.to} title={link.name} key={key}>{link.name}</CustomLink>
                            )
                        })
                    }
                </ul>

                <ul className="nav-links-icons">
                    <li className="nav-links-icons-li">
                        <a href="https://www.linkedin.com/in/makkiah/" className='nav-links-li-link nav-links-li-link-icon' target='_blank' rel="noreferrer" aria-label="Visit my LinkedIn" title="LinkedIn">
                            <i className="fa-brands fa-linkedin"></i>
                        </a>
                    </li>
                    <li className="nav-links-icons-li">
                        <a href="https://www.instagram.com/_makkiah/?hl=en" className='nav-links-li-link nav-links-li-link-icon' target='_blank' rel="noreferrer" aria-label="Visit my Instagram" title="Instagram">
                            <i className="fa-brands fa-instagram"></i>
                        </a>
                    </li>
                    <li className="nav-links-icons-li">
                        <a href="https://github.com/Makkiah" className='nav-links-li-link nav-links-li-link-icon' target='_blank' rel="noreferrer" aria-label="Visit my GitHub" title="GitHub">
                            <i className="fa-brands fa-github"></i>
                        </a>
                    </li>
                    {/* <li className="nav-links-icons-li">
                        <a href="https://dl.dropboxusercontent.com/scl/fi/w5oh28m8fjin305u00orv/Makkiah-Resume-2024.pdf?rlkey=enszmyubpbv99zbjq2dnj99j8&st=sohbcb4s&dl=0" className='nav-links-li-link' target='_blank' rel="noreferrer" aria-label="Download Resume" title="Download Resume">
                            <i className="fa-solid fa-download"></i>
                        </a>
                    </li> */}
                </ul>
            </div>
            </div>
        </div>
    )
}



export {Navigation, CustomLink};