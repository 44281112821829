import './index.scss';
import {Link} from 'react-router-dom';


function Home() {
    return (
        <>
            <div className="home-container">
                <div className='home-card'>
                    <h2 className='home-title'>TL;DR</h2>
                    <p className='home-text margb'>
                        Just a guy from Ohio who grew up in Georgia with an affinity for the arts, science, and finance. In my free time, I play guitar, make websites, read books on education and philosophy, hang out with friends, watch anime, and pretty much try to live a chill, respectable life.
                    </p>
                    <h2 className='home-title'>The Journey...</h2>
                    <p className='home-text'>
                        I&#39;ve been making music since a kid, so over the years I&#39;ve recorded songs and delved deeper into my love for guitar and various genres. If you want to give my music a listen, it&#39;s available on most music services (Spotify, etc.). I use my real name as my artist name, so searching for &#39;Makkiah&#39; should get you there.
                        Other than making music, I&#39;m usually creating websites for my day job and personal business. Back when I attended high school, I fell in love with mobile apps, and once I heard that Flappy Bird generated around $15k per day, I became even more interested in what it&#39;d take to create something like that. My best friend and I wrote a business plan for an app company, filled out forms for angel investors and tech incubators, and even gave his stepdad a copy of the proposal, but I assume they weren&#39;t interested in a couple of green-eyed kids with no skills and just ideas.
                        <br/><br/>
                        I never let go the thought of creating something online that others could use and value. So after taking a couple of years off school after high school, taking my mom&#39;s advice to learn about getting a CCNA, going to Georgia State University for Computer Science, and then leaving after a semester to bet on learning enough in an online digital marketing course to land a job, that endeavor eventually led me to learn web development so I could better present what I hoped to market online.
                        3 and 1/2 years after I left college, while working at a car rental shop in Buckhead, Atlanta, I was able to finally land a web development job working for a tobacco and cbd wholesale company in Norcross, GA (a 3-hour train/bus ride from my mom&#39;s house).
                        During the 2020 pandemic, my dev job let me go, and a prior relationship led me to take the chance at moving to Ohio and finding a job while living in my brothers basement. After 4 months, I found a place in Dayton (my hometown) and lived there for a year before moving to Columbus for work on the marketing team at Power Distributors.
                        <br/><br/>
                        I still live in Columbus and overall look forward to making even more progress toward a fulfilling life. If you want to learn more about me and keep up with what I&#39;m currently doing, feel free to visit my blog. I&#39;ve been journaling for roughly 5 years, so my blog reads similar to how I journal.
                    </p>
                </div>
                <div className='home-div'>
                    <Link to='/music' className="home-btn btn1">Music</Link>
                    <Link to='/resume' className="home-btn btn2">Résumé</Link>
                    <a href='https://github.com/Makkiah' target="_blank" rel="noopener noreferrer" className="home-btn btn3">GitHub</a>
                </div>
            </div>
        </>
    );
}

export default Home;